<template>
	<iframe
		v-if="!loading"
		:src="`/img/${specialOffer.file}`"
		type="application/pdf"
		class="w-100 h-100 border-1s"
	>
		Oops! an error has occurred.
	</iframe>
	<v-card
		v-else
		class="text-center font-weight-300 text-h3 py-8"
	>
		Loading
		<v-icon color="black" size="36">fas fa-spinner fa-pulse</v-icon>
	</v-card>
</template>
<script>

export default {
	name: 'SpecialOffers',
	components: {},
	computed: {},
	data: () => ({
		loading: false,
		specialOffer: null,
	}),
	methods: {

		getSpecialOffer() {
			this.loading = true;
			this.$store.dispatch('DASHBOARD_STORE/getSpecialOffer')
				.then(resp => {
					if (resp.data.success && resp.data.data) {
						this.specialOffer = resp.data.data;
					} else {
						this.$router.push({name: 'Dashboard'});
					}
					this.loading = false;
				})
				.catch(err => {
					console.error(err)
					this.loading = false;
					this.specialOffer = false;
					this.dialog = false
				});
		},
	},
	created() {
		this.getSpecialOffer();
	}
};
</script>
